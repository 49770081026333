import '../css/App.css';
import DevMessage from '../components/DevMessage/DevMessage';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

function About() {
    return (
        <>
            <DevMessage />
            <Navbar />
            <div className="App">
                <header>
                    <p>
                        This website is tool to simulate crypto strategy.
                        More information will be added soon.
                    </p>
                </header>
            </div>
            <Footer />
        </>
    );
}

export default About;