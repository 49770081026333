import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

import './Footer.css'; // Import your CSS file for styling

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-content">
                    <div className="footer-social">
                        <Link to="https://www.linkedin.com/in/benjamin-demolin/" className="social-link">
                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                        </Link>
                        <Link to="https://github.com/BenjaminDemolin" className="social-link">
                            <FontAwesomeIcon icon={faGithub} size="2x" />
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
