import React, { useState } from "react";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

import './SimulationDataChart.css'; // Import a CSS file for styling

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const SimulationDataChart = ({ ticker, data, trade }) => {
  // Initialize state variables
  const [currentPage, setCurrentPage] = useState(1);

  if (!data || data.length === 0) {
    return <div></div>;
  }

  const rowsPerPage = 10;

  // Calculate the start and end index of the rows to display on the current page
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  // Filter the trades to display only the rows for the current page
  const tradesToDisplay = trade.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(trade.length / rowsPerPage);


  const maxChartPoints = 10080

  var numberOfTrades = Math.ceil((trade.length) / 2)
  var maxProfitLossAbsolute = 0
  var firstProfitLossAbsolute = 0
  var maxProfitLossRelative = 0
  if (trade.length !== 0) {
    firstProfitLossAbsolute = trade[0]["total_profit_loss_absolute"].toFixed(2)
    maxProfitLossAbsolute = trade[trade.length - 1]["total_profit_loss_absolute"].toFixed(2)
    maxProfitLossRelative = trade[trade.length - 1]["total_profit_loss_relative"].toFixed(2)
  }
  var columnNames = [];
  var formatedColumnNames = [];
  for (let key in trade[0]) {
    if (key !== "close_time") {
      columnNames.push(key);
      formatedColumnNames.push(key.replace(/_/g, ' '));
    } else {
      // insert close time in second
      columnNames.splice(1, 0, key);
      formatedColumnNames.splice(1, 0, key.replace(/_/g, ' '));
    }
  }

  var priceValues = []
  var sample = 0
  if (data.length > maxChartPoints) {
    sample = Math.ceil(data.length / maxChartPoints)
    const sampledData = [];
    for (let i = 0; i < data.length; i += sample) {
      sampledData.push(data[i]);
    }
    priceValues = sampledData.map(item => ({
      x: new Date(item.close_time),
      y: item.close_price
    }));
  }
  else {
    priceValues = data.map(item => ({
      x: new Date(item.close_time),
      y: item.close_price
    }));
  }

  const priceChartData = {
    datasets: [
      {
        data: priceValues,
        borderColor: "#007bff", // Line color
        borderWidth: 1, // Line width
        pointRadius: 1,
        tension: 0.4, // Line tension
        hidden: false // Ensure the dataset is not hidden initially
      }
    ]
  };

  const priceChartOptions = {
    response: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day"
        },
        ticks: {
          color: "#fff" // X-axis label color
        }
      },
      y: {
        beginAtZero: false,
        ticks: {
          color: "#fff" // Y-axis label color
        }
      }
    },
    plugins: {
      legend: {
        display: false, // Hide legend
        labels: {
          color: "#fff" // Legend label color
        }
      },
      title: {
        display: true,
        text: "Ticker price",
        color: "#fff" // Chart title color
      },
      tooltip: {
        bodyColor: "#fff",
      }
    }
  };

  var profitLossRelativeValues = []
  var profitLossAbsoluteValues = []
  if (trade.length > maxChartPoints) {
    sample = Math.ceil(trade.length / maxChartPoints)
    const sampledTrade = [];
    for (let i = 0; i < trade.length; i += sample) {
      sampledTrade.push(trade[i]);
    }
    profitLossRelativeValues = sampledTrade.map(item => ({
      x: new Date(item["close_time"]),
      y: item["total_profit_loss_relative"]
    }));
    profitLossAbsoluteValues = sampledTrade.map(item => ({
      x: new Date(item["close_time"]),
      y: item["total_profit_loss_absolute"]
    }))
  }
  else {
    profitLossRelativeValues = trade.map(item => ({
      x: new Date(item["close_time"]),
      y: item["total_profit_loss_relative"]
    }));
    profitLossAbsoluteValues = trade.map(item => ({
      x: new Date(item["close_time"]),
      y: item["total_profit_loss_absolute"]
    }));
  }

  const profitLossRelativeChartData = {
    datasets: [
      {
        data: profitLossRelativeValues,
        borderColor: "#007bff", // Line color
        borderWidth: 1, // Line width
        pointRadius: 1,
        tension: 0, // Line tension
        hidden: false // Ensure the dataset is not hidden initially
      }
    ]
  };

  const profitLossRelativeChartOptions = {
    response: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day"
        },
        ticks: {
          color: "#fff" // X-axis label color
        }
      },
      y: {
        beginAtZero: false,
        ticks: {
          color: "#fff" // Y-axis label color
        }
      }
    },
    plugins: {
      legend: {
        display: false, // Hide legend
        labels: {
          color: "#fff" // Legend label color
        }
      },
      title: {
        display: true,
        text: "Total profit/Loss relative (%)",
        color: "#fff" // Chart title color
      },
      tooltip: {
        bodyColor: "#fff",
      }
    }
  };

  const profitLossAbsoluteChartData = {
    datasets: [
      {
        data: profitLossAbsoluteValues,
        borderColor: "#007bff", // Line color
        borderWidth: 1, // Line width
        pointRadius: 1,
        tension: 0, // Line tension
        hidden: false // Ensure the dataset is not hidden initially
      }
    ]
  };

  const profitLossAbsoluteChartOptions = {
    response: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day"
        },
        ticks: {
          color: "#fff" // X-axis label color
        }
      },
      y: {
        beginAtZero: false,
        ticks: {
          color: "#fff" // Y-axis label color
        }
      }
    },
    plugins: {
      legend: {
        display: false, // Hide legend
        labels: {
          color: "#fff" // Legend label color
        }
      },
      title: {
        display: true,
        text: "Total profit/Loss absolute ($)",
        color: "#fff" // Chart title color
      },
      tooltip: {
        bodyColor: "#fff",
      }
    }
  };

  return (
    <div>
      <div className="trades-data-container">
        <div className="trades-data-column">
          <p>Number of trades</p>
          <p>{numberOfTrades}</p>
        </div>
        <div className="trades-data-column">
          <p>Total relative profit/loss</p>
          <p>{maxProfitLossRelative} %</p>
        </div>
        <div className="trades-data-column">
          <p>Profit/loss</p>
          <p>{maxProfitLossAbsolute} $ ({(maxProfitLossAbsolute - firstProfitLossAbsolute).toFixed(2)} $)</p>
        </div>
      </div>
      {tradesToDisplay.length > 1 && (
        <>
          <div className="line-container">
            <Line options={profitLossRelativeChartOptions} data={profitLossRelativeChartData} />
          </div>
          <div className="line-container">
            <Line options={profitLossAbsoluteChartOptions} data={profitLossAbsoluteChartData} />
          </div>
        </>
      )}
      <div className="line-container">
        <Line options={priceChartOptions} data={priceChartData} />
      </div>
      <div className="table-container">
        <table className="styled-table">
          <thead>
            <tr>
              {formatedColumnNames.map((columnName) => (
                <th key={columnName}>{columnName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tradesToDisplay.map((item, index) => (
              <tr key={index} className={item.side === 'in' ? 'in-row' : 'out-row'}>
                {columnNames.map((columnName) => (
                  <td key={columnName}>
                    {columnName.toLowerCase().includes('time')
                      ? new Date(item[columnName]).toLocaleString()
                      : typeof item[columnName] === 'number'
                        ? columnName === 'total_profit_loss_relative' || columnName === 'profit_loss'
                          ? item[columnName].toFixed(2) // Format floats with 2 decimal places
                          : item[columnName] 
                        : item[columnName]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {// tradesToDisplay to display is not empty
        tradesToDisplay.length > 0 && (
          <div className="pagination">
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
            >
              First page
            </button>
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <button
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last page
            </button>
          </div>
        )}
    </div>
  );
};

export default SimulationDataChart;
