import React from 'react';
import './DevMessage.css';

const DevMessage = () => {
    return (
        <div className='in-progress-message'>
            <p>Project in progress. This is an alpha version. New features incoming !</p>
        </div>
    );
};

export default DevMessage;
