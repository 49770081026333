import React, { useState, useEffect } from 'react';
import SimulationDataChart from '../components/SimulationDataChart/SimulationDataChart';
import DevMessage from '../components/DevMessage/DevMessage';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import Indication from '../components/Indication/Indication';
import Select from 'react-select';
import '../css/App.css';
import '../css/Simulation.css';

function Simulation() {
  const [startDate, setStartDate] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [tickersData, setTickersData] = useState({});
  const [selectedExchange, setSelectedExchange] = useState('');
  const [selectedTicker, setSelectedTicker] = useState('');
  const [period, setPeriod] = useState('');
  const [periodsData, setPeriodsData] = useState([]);
  const [option, setOption] = useState('long');
  const [leverage, setLeverage] = useState(1);
  const [fees, setFees] = useState(0.1);
  const [periodMaxInTrade, setPeriodMaxInTrade] = useState(0);
  const [simulatedValue, setSimulatedValue] = useState(0);
  const [takeProfitType, settakeProfitType] = useState('relative');
  const [takeProfitValue, setValueTakeProfit] = useState(0);
  const [stopLossType, setStopLossType] = useState('relative');
  const [stopLossValue, setValueStopLoss] = useState(0);
  const [inAndOr, setInAndOr] = useState('and');
  const [inRSICondition, setInRSICondition] = useState('0');
  const [inRSIConditionError, setInRSIConditionError] = useState('');
  const [inRSITrigger1, setInRSITrigger1] = useState('');
  const [inRSITrigger2, setInRSITrigger2] = useState('');
  const [inRSIPeriod, setInRSIPeriod] = useState('');
  const [inSMACondition, setInSMACondition] = useState('0');
  const [inSMAConditionError, setInSMAConditionError] = useState('');
  const [inSMAPeriod, setInSMAPeriod] = useState('');
  const [inEMACondition, setInEMACondition] = useState('0');
  const [inEMAConditionError, setInEMAConditionError] = useState('');
  const [inEMAPeriod, setInEMAPeriod] = useState('');
  const [outAndOr, setOutAndOr] = useState('and');
  const [outRSICondition, setOutRSICondition] = useState('0');
  const [outRSIConditionError, setOutRSIConditionError] = useState('');
  const [outRSITrigger1, setOutRSITrigger1] = useState('');
  const [outRSITrigger2, setOutRSITrigger2] = useState('');
  const [outRSIPeriod, setOutRSIPeriod] = useState('');
  const [outSMACondition, setOutSMACondition] = useState('0');
  const [outSMAConditionError, setOutSMAConditionError] = useState('');
  const [outSMAPeriod, setOutSMAPeriod] = useState('');
  const [outEMACondition, setOutEMACondition] = useState('0');
  const [outEMAConditionError, setOutEMAConditionError] = useState('');
  const [outEMAPeriod, setOutEMAPeriod] = useState('');
  const [loading, setLoading] = useState(false);

  const [simulationData, setSimulationData] = useState([]);


  // Fetch tickers when the page is loaded
  useEffect(() => {
    fetch('https://plottingmate.com:5000/API/crypto/get_tickers')
      .then((response) => response.json())
      .then((data) => {
        setTickersData(data);
        const firstExchange = Object.keys(data)[0];
        setSelectedExchange(firstExchange);
        const tickersForFirstExchange = data[firstExchange];
        if (tickersForFirstExchange.length > 0) {
          setSelectedTicker(tickersForFirstExchange[0]);
        }
      })
      .catch((error) => {
        console.error('Error fetching tickers:', error);
      });


    fetch('https://plottingmate.com:5000/API/crypto/get_periods')
      .then((response) => response.json())
      .then((data) => {
        setPeriodsData(data.periods);
        setPeriod(data.periods[0]); // Set the default selected period
      })
      .catch((error) => {
        console.error('Error fetching periods:', error);
      });
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleExchangeChange = (e) => {
    const selectedExchange = e.target.value;
    setSelectedExchange(selectedExchange);
    const tickersForSelectedExchange = tickersData[selectedExchange];
    if (tickersForSelectedExchange.length > 0) {
      setSelectedTicker(tickersForSelectedExchange[0]);
    }
  };

  const handleTickerChange = (e) => {
    setSelectedTicker(e.target.value);
  };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };

  const handleLeverageChange = (e) => {
    setLeverage(e.target.value);
  };

  const handleFeesChange = (e) => {
    setFees(e.target.value);
  };

  const handlePeriodMaxInTradeChange = (e) => {
    setPeriodMaxInTrade(e.target.value);
  };

  const handleSimulatedValueChange = (e) => {
    setSimulatedValue(e.target.value);
  };

  const handleTakeProfitTypeChange = (e) => {
    settakeProfitType(e.target.value);
  };

  const handleTakeProfitValueChange = (e) => {
    setValueTakeProfit(e.target.value);
  };

  const handleStopLossTypeChange = (e) => {
    setStopLossType(e.target.value);
  };

  const handleStopLossValueChange = (e) => {
    setValueStopLoss(e.target.value);
  };

  const handleInAndOrChange = (e) => {
    setInAndOr(e.target.value);
  };

  const handleInRSIConditionChange = (e) => {
    setInRSICondition(e.target.value);
  };

  const handleInRSITrigger1Change = (e) => {
    setInRSITrigger1(e.target.value);
  };

  const handleInRSITrigger2Change = (e) => {
    setInRSITrigger2(e.target.value);
  };

  const handleInRSIPeriodChange = (e) => {
    setInRSIPeriod(e.target.value);
  };

  const handleInSMAConditionChange = (e) => {
    setInSMACondition(e.target.value);
  };

  const handleInSMAPeriodChange = (e) => {
    setInSMAPeriod(e.target.value);
  };

  const handleInEMAConditionChange = (e) => {
    setInEMACondition(e.target.value);
  };

  const handleInEMAPeriodChange = (e) => {
    setInEMAPeriod(e.target.value);
  };

  const handleOutAndOrChange = (e) => {
    setOutAndOr(e.target.value);
  };

  const handleOutRSIConditionChange = (e) => {
    setOutRSICondition(e.target.value);
  };

  const handleOutRSITrigger1Change = (e) => {
    setOutRSITrigger1(e.target.value);
  };

  const handleOutRSITrigger2Change = (e) => {
    setOutRSITrigger2(e.target.value);
  };

  const handleOutRSIPeriodChange = (e) => {
    setOutRSIPeriod(e.target.value);
  };

  const handleOutSMAConditionChange = (e) => {
    setOutSMACondition(e.target.value);
  };

  const handleOutSMAPeriodChange = (e) => {
    setOutSMAPeriod(e.target.value);
  };

  const handleOutEMAConditionChange = (e) => {
    setOutEMACondition(e.target.value);
  };

  const handleOutEMAPeriodChange = (e) => {
    setOutEMAPeriod(e.target.value);
  };

  const options = (tickersData[selectedExchange] || []).map((ticker) => ({
    value: ticker,
    label: ticker,
  }));

  const customStyles = {
    control: (defaultStyles) => ({
      ...defaultStyles,
      width: 'calc(100% - 22px)',
      border: '1px solid #555',
      borderRadius: '5px',
      fontSize: '16px',
      backgroundColor: '#444',
      color: '#fff',
      boxShadow: "none"
    }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? '#444' : '#fff',
      backgroundColor: state.isSelected ? '#a0a0a0' : '#444',
    }),
    singleValue: (defaultStyles, state) => ({
      ...defaultStyles,
      color: 'white',
      fontSize: state.selectProps.myFontSize,
    }),
  };

  const handleSimulateClick = () => {
    if (!startDate || isNaN(new Date(startDate).getTime())) {
      setStartDateError('Please select a valid start date.');
      return; // Exit the function early
    } else {
      setStartDateError('');
    }
    if (!endDate || isNaN(new Date(endDate).getTime())) {
      setEndDateError('Please select a valid end date.');
      return; // Exit the function early
    } else {
      setEndDateError('');
    }
    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      setEndDateError('End date must be greater than start date.');
      return; // Exit the function early
    } else {
      setEndDateError('');
    }
    if (inRSICondition !== "0" && (inRSITrigger1 === "" || inRSIPeriod === "")) {
      setInRSIConditionError('Please fill the RSI parameters');
      return;
    }
    else {
      setInRSIConditionError('');
    }
    if ((inRSICondition === "6" || inRSICondition === "7") && inRSITrigger2 === "") {
      setInRSIConditionError('Please fill the RSI parameters');
      return;
    }
    else {
      setInRSIConditionError('');
    }
    if (inSMACondition !== "0" && inSMAPeriod === "") {
      setInSMAConditionError('Please fill the SMA parameters');
      return;
    }
    else {
      setInSMAConditionError('');
    }
    if (inEMACondition !== "0" && inEMAPeriod === "") {
      setInEMAConditionError('Please fill the EMA parameters');
      return;
    }
    else {
      setInEMAConditionError('');
    }
    if (outRSICondition !== "0" && (outRSITrigger1 === "" || outRSIPeriod === "")) {
      setOutRSIConditionError('Please fill the RSI parameters');
      return;
    }
    else {
      setOutRSIConditionError('');
    }
    if ((outRSICondition === "6" || outRSICondition === "7") && outRSITrigger2 === "") {
      setOutRSIConditionError('Please fill the RSI parameters');
      return;
    }
    else {
      setOutRSIConditionError('');
    }
    if (outSMACondition !== "0" && outSMAPeriod === "") {
      setOutSMAConditionError('Please fill the SMA parameters');
      return;
    }
    else {
      setOutSMAConditionError('');
    }
    if (outEMACondition !== "0" && outEMAPeriod === "") {
      setOutEMAConditionError('Please fill the EMA parameters');
      return;
    }
    else {
      setOutEMAConditionError('');
    }
    setLoading(true);

    const startDateObject = new Date(startDate).getTime();
    console.log('Start Date:', startDate);
    console.log('Start Date Timestamp:', startDateObject);
    const endDateObject = new Date(endDate).getTime();
    console.log('End Date:', endDate);
    console.log('End Date Timestamp:', endDateObject);
    console.log('Selected Exchange:', selectedExchange);
    console.log('Selected Ticker:', selectedTicker);
    console.log('Selected Period:', period);
    console.log('Option:', option);
    console.log('Leverage:', leverage);
    console.log('Fees:', fees);
    console.log('Period max in trade:', periodMaxInTrade);
    console.log('Simulated value:', simulatedValue);
    console.log('Take profit type:', takeProfitType);
    console.log('Take profit value:', takeProfitValue);
    console.log('Stop loss type:', stopLossType);
    console.log('Stop loss value:', stopLossValue);
    console.log('In and/or:', inAndOr);
    console.log('In RSI condition:', inRSICondition);
    console.log('In RSI trigger 1:', inRSITrigger1);
    console.log('In RSI trigger 2:', inRSITrigger2);
    console.log('In RSI period:', inRSIPeriod);
    console.log('In SMA condition:', inSMACondition);
    console.log('In SMA period:', inSMAPeriod);
    console.log('In EMA condition:', inEMACondition);
    console.log('In EMA period:', inEMAPeriod);
    console.log('Out and/or:', outAndOr);
    console.log('Out RSI condition:', outRSICondition);
    console.log('Out RSI trigger 1:', outRSITrigger1);
    console.log('Out RSI trigger 2:', outRSITrigger2);
    console.log('Out RSI period:', outRSIPeriod);
    console.log('Out SMA condition:', outSMACondition);
    console.log('Out SMA period:', outSMAPeriod);
    console.log('Out EMA condition:', outEMACondition);
    console.log('Out EMA period:', outEMAPeriod);

    var url = `https://plottingmate.com:5000/API/crypto/get_basic_simulation?platform=${selectedExchange}&ticker=${selectedTicker}&period=${period}&start_date=${startDateObject}&end_date=${endDateObject}&option=${option}&leverage=${leverage}&fees=${fees / 100}&period_max_in_trade=${periodMaxInTrade}&simulated_value=${simulatedValue}&take_profit_type=${takeProfitType}&take_profit_value=${takeProfitValue}&stop_loss_type=${stopLossType}&stop_loss_value=${stopLossValue}&in_parameters=${inAndOr}&out_parameters=${outAndOr}`
    if (inRSICondition !== "0") {
      url += (`&in_parameters_rsi_condition=${inRSICondition}&in_parameters_rsi_trigger1=${inRSITrigger1}&in_parameters_rsi_period=${inRSIPeriod}`)

      if (inRSICondition === "6" || inRSICondition === "7") {
        url += (`&in_parameters_rsi_trigger2=${inRSITrigger2}`)
      }
    }
    if (inSMACondition !== "0") {
      url += (`&in_parameters_sma_condition=${inSMACondition}&in_parameters_sma_period=${inSMAPeriod}`)
    }
    if (inEMACondition !== "0") {
      url += (`&in_parameters_ema_condition=${inEMACondition}&in_parameters_ema_period=${inEMAPeriod}`)
    }
    if (outRSICondition !== "0") {
      url += (`&out_parameters_rsi_condition=${outRSICondition}&out_parameters_rsi_trigger1=${outRSITrigger1}&out_parameters_rsi_period=${outRSIPeriod}`)
      if (outRSICondition === "6" || outRSICondition === "7") {
        url += (`&out_parameters_rsi_trigger2=${outRSITrigger2}`)
      }
    }
    if (outSMACondition !== "0") {
      url += (`&out_parameters_sma_condition=${outSMACondition}&out_parameters_sma_period=${outSMAPeriod}`)
    }
    if (outEMACondition !== "0") {
      url += (`&out_parameters_ema_condition=${outEMACondition}&out_parameters_ema_period=${outEMAPeriod}`)
    }
    console.log('URL:', url);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log('Simulation Data:', data);
        setSimulationData(data);
      })
      .catch((error) => {
        console.error('Error during simulation:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <DevMessage />
      <Navbar />
      <div className="App">
        <h2>Global parameters</h2>
        {/* Global parameters */}
        <div className="global-parameters">
          <div className="date-container">
            {/* Start date */}
            <div className="input-container">
              {<Indication labelText="Start Date:" hoverText="Choose the date when you want the trading simulation to begin." />}
              <input
                type="datetime-local"
                id="startDate"
                value={startDate}
                onChange={handleStartDateChange}
              />
              <p className="error-message">{startDateError}</p>
            </div>
            {/* End date */}
            <div className="input-container">
              {<Indication labelText="End Date:" hoverText="Specify the date when you want the trading simulation to conclude." />}
              <input
                type="datetime-local"
                id="endDate"
                value={endDate}
                onChange={handleEndDateChange}
              />
              <p className="error-message">{endDateError}</p>
            </div>
          </div>
          <div className="ticker-container">
            {/* Exchange */}
            <div className="input-container">
              {<Indication labelText="Select an Exchange:" hoverText="Pick the platform where you want to simulate your trades" />}
              <select
                id="exchange"
                value={selectedExchange}
                onChange={handleExchangeChange}
              >
                {Object.keys(tickersData).map((exchange) => (
                  <option key={exchange} value={exchange}>
                    {exchange}
                  </option>
                ))}
              </select>
            </div>
            {/* Tickers */}
            <div className="input-container">
              {<Indication labelText="Select a Ticker:" hoverText="Choose a specific financial instrument for your simulation." />}
              <Select
                id="ticker"
                options={options}
                onChange={(selectedOption) => {
                  if (selectedOption && selectedOption.value) {
                    handleTickerChange({ target: { value: selectedOption.value } });
                  }
                }}
                styles={customStyles}
                defaultValue={options[0]?.value}
                placeholder={options[0]?.value}
              />
            </div>
            {/* Period */}
            <div className="input-container">
              {<Indication labelText="Select a Period:" hoverText="Decide the timeframe for your simulation (e.g., hourly, daily)." />}
              <select id="period" value={period} onChange={handlePeriodChange}>
                {periodsData.map((periodOption) => (
                  <option key={periodOption} value={periodOption}>
                    {periodOption}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="options-container">
            {/* Option */}
            <div className="input-container">
              {<Indication labelText="Option:" hoverText="Decide your trading strategy - 'Long' if you expect prices to rise, 'Short' if you anticipate a decrease." />}
              <select id="option" value={option} onChange={handleOptionChange}>
                <option key="long" value="long">Long</option>
                <option key="short" value="short">Short</option>
              </select>
            </div>
            {/* Leverage */}
            <div className="input-container">
              {<Indication labelText="Leverage:" hoverText="Specify how much you want to amplify your trading position. Use leverage to multiply both your potential gains and losses." />}
              <input
                type="number"
                id="leverage"
                min='1'
                value={leverage}
                onChange={handleLeverageChange}
              />
            </div>
            {/* Fees */}
            <div className="input-container">
              {<Indication labelText="Fees (%):" hoverText="Set the transaction fees as a percentage of your trade value." />}
              <input
                type='number'
                step="0.001"
                min='0'
                max='1'
                value={fees}
                onChange={handleFeesChange}
              />
            </div>
          </div>
          <div className="global-trading-container">
            {/* Period max in trade */}
            <div className="input-container">
              {<Indication labelText="Period maximum in trade:" hoverText="Set the maximum duration for each trading period. If set to 0, it means there is no maximum duration." />}
              <input
                type="number"
                id="period_max_in_trade"
                min='0'
                value={periodMaxInTrade}
                onChange={handlePeriodMaxInTradeChange}
              />
            </div>
            {/* Simulated value */}
            <div className="input-container">
              {<Indication labelText="Simulated value ($):" hoverText="Determine the initial simulated amount in dollars for your trades. If set to 0, simulate the difference between the price at trade-in and trade-out." />}
              <input
                type="number"
                id="simulated_value"
                min='0'
                value={simulatedValue}
                onChange={handleSimulatedValueChange}
              />
            </div>
          </div>
          {/* Take profit */}
          <div className="stop-parameters-type-container">
            {/* Take profit type */}
            <div className='take-profit-type'>
              {<Indication labelText="Take profit type:" hoverText="Choose how you want to set your take profit - either as a percentage (relative) or a specific dollar amount (absolute)." />}
              <select id="take_profit_type" value={takeProfitType} onChange={handleTakeProfitTypeChange}>
                <option key="relative" value="relative">Relative</option>
                <option key="absolute" value="absolute">Absolute</option>
              </select>
            </div>
            {/* Stop loss type */}
            <div className='stop-loss-type'>
              {<Indication labelText="Stop loss type:" hoverText="Choose how you want to set your stop loss - either as a percentage (relative) or a specific dollar amount (absolute)." />}
              <select id="stop_loss_type" value={stopLossType} onChange={handleStopLossTypeChange}>
                <option key="relative" value="relative">Relative</option>
                <option key="absolute" value="absolute">Absolute</option>
              </select>
            </div>
          </div>
          {/* Stop loss */}
          <div className="stop-parameters-value-container">
            {/* Take profit value */}
            <div>
              {takeProfitType === 'relative' ? <Indication labelText="Take profit value (%):" hoverText="Specify the value at which you want to take profits, either in percentage terms or a specific dollar amount. If set to 0, not used." /> : <Indication labelText="Take profit value ($):" hoverText="Specify the value at which you want to take profits, either in percentage terms or a specific dollar amount. If set to 0, not used." />}
              <input
                type="number"
                id="take_profit_value"
                min='0'
                value={takeProfitValue}
                onChange={handleTakeProfitValueChange}
              />
            </div>
            {/* Stop loss value */}
            <div>
              {stopLossType === 'relative' ? <Indication labelText="Stop loss value (%):" hoverText="Specify the value at which you want to trigger a stop loss, either in percentage terms or a specific dollar amount. If set to 0, not used." /> : <Indication labelText="Stop loss value ($):" hoverText="Specify the value at which you want to trigger a stop loss, either in percentage terms or a specific dollar amount. If set to 0, not used." />}
              <input
                type="number"
                id="stop_loss_value"
                min='0'
                value={stopLossValue}
                onChange={handleStopLossValueChange}
              />
            </div>
          </div>
        </div>
        <h2>Simulation parameters</h2>
        {/* Simulation parameters */}
        <div className="simulation-parameters">
          {/* In parameters */}
          <div className="in-parameters-container">
            {/* In parameters - Title */}
            <div>
              <h2>In parameters</h2>
            </div>
            {/* In parameters - and/or */}
            <div className='and_or_condiction'>
              {<Indication labelText="Condition:" hoverText="Choose whether the conditions within the 'In' parameters should be met simultaneously ('And') or at least one condition should be met ('Or')." />}
              <select id="in_and_or" value={inAndOr} onChange={handleInAndOrChange}>
                <option key="and" value="and">And</option>
                <option key="or" value="or">Or</option>
              </select>
            </div>
            {/* In parameters - parameters */}
            <div>
              {/* In parameters - RSI */}
              <div>
                {/* In parameters - RSI condition */}
                <div>
                  {<Indication labelText="RSI Condition:" hoverText="Choose the condition type based on the Relative Strength Index (RSI) within the 'In' parameters. Trigger between 0 and 100. If 'None', RSI is not used." />}
                  <select id="in_parameters_rsi_condition" value={inRSICondition} onChange={handleInRSIConditionChange}>
                    <option key="0" value="0">None</option>
                    <option key="1" value="1">Superior or equal</option>
                    <option key="2" value="2">Inferior or equal</option>
                    <option key="3" value="3">Superior</option>
                    <option key="4" value="4">Inferior</option>
                    <option key="5" value="5">Equal</option>
                    <option key="6" value="6">Between</option>
                    <option key="7" value="7">Out of</option>
                  </select>
                  <p className="error-message">{inRSIConditionError}</p>
                </div>
                {/* In parameters - RSI trigger1 */}
                <div>
                  {inRSICondition !== "0" ? (<label id="in_rsi_trigger1">RSI trigger {(inRSICondition !== "6" && inRSICondition !== "7") ? null : '1'}:</label>) : null}
                  <input
                    type="number"
                    id="in_rsi_trigger1"
                    value={inRSITrigger1}
                    min='0'
                    max='100'
                    step='0.5'
                    onChange={handleInRSITrigger1Change}
                    disabled={inRSICondition === "0"}
                    hidden={inRSICondition === "0"}
                  />
                </div>
                {/* In parameters - RSI trigger2 */}
                <div>
                  {(inRSICondition === "6" || inRSICondition === "7") ? (<label id="in_rsi_trigger2">RSI trigger 2:</label>) : null}
                  <input
                    type="number"
                    id="in_rsi_trigger2"
                    value={inRSITrigger2}
                    min='0'
                    max='100'
                    step='0.5'
                    onChange={handleInRSITrigger2Change}
                    disabled={(inRSICondition !== "6" && inRSICondition !== "7")}
                    hidden={(inRSICondition !== "6" && inRSICondition !== "7")}
                  />
                </div>
                {/* In parameters - RSI period */}
                <div>
                  {inRSICondition !== "0" ? (<label id="in_rsi_period" >RSI period:</label>) : null}
                  <input
                    type="number"
                    id="in_rsi_period"
                    value={inRSIPeriod}
                    min='1'
                    step='1'
                    onChange={handleInRSIPeriodChange}
                    disabled={inRSICondition === "0"}
                    hidden={inRSICondition === "0"}
                  />
                </div>
              </div>
              {/* In parameters - SMA */}
              <div>
                {/* In parameters - SMA condition */}
                <div>
                  {<Indication labelText="SMA Condition:" hoverText="Choose the condition type based on the Simple Moving Average (SMA) within the 'In' parameters. SMA is compared to the price. The SMA is compared to the price. If the condition is set to 'superior,' execute a trade when the SMA is higher than the price. If 'None', SMA is not used." />}
                  <select id="in_parameters_sma_condition" value={inSMACondition} onChange={handleInSMAConditionChange}>
                    <option key="0" value="0">None</option>
                    <option key="1" value="1">Superior or equal</option>
                    <option key="2" value="2">Inferior or equal</option>
                    <option key="3" value="3">Superior</option>
                    <option key="4" value="4">Inferior</option>
                    <option key="5" value="5">Equal</option>
                  </select>
                  <p className="error-message">{inSMAConditionError}</p>
                </div>
                {/* In parameters - SMA period */}
                <div>
                  {inSMACondition !== "0" ? (<label id="in_SMA_period" >SMA period:</label>) : null}
                  <input
                    type="number"
                    id="in_sma_period"
                    value={inSMAPeriod}
                    min='1'
                    step='1'
                    onChange={handleInSMAPeriodChange}
                    disabled={inSMACondition === "0"}
                    hidden={inSMACondition === "0"}
                  />
                </div>
              </div>
              {/* In parameters - EMA */}
              <div>
                {/* In parameters - EMA condition */}
                <div>
                  {<Indication labelText="EMA Condition:" hoverText="Choose the condition type based on the Exponential Moving Average (EMA) within the 'In' parameters. EMA is compared to the price. The EMA is compared to the price. If the condition is set to 'superior,' execute a trade when the EMA is higher than the price. If 'None', EMA is not used." />}
                  <select id="in_parameters_ema_condition" value={inEMACondition} onChange={handleInEMAConditionChange}>
                    <option key="0" value="0">None</option>
                    <option key="1" value="1">Superior or equal</option>
                    <option key="2" value="2">Inferior or equal</option>
                    <option key="3" value="3">Superior</option>
                    <option key="4" value="4">Inferior</option>
                    <option key="5" value="5">Equal</option>
                  </select>
                  <p className="error-message">{inEMAConditionError}</p>
                </div>
                {/* In parameters - EMA period */}
                <div>
                  {inEMACondition !== "0" ? (<label id="in_EMA_period" >EMA period:</label>) : null}
                  <input
                    type="number"
                    id="in_ema_period"
                    value={inEMAPeriod}
                    min='1'
                    step='1'
                    onChange={handleInEMAPeriodChange}
                    disabled={inEMACondition === "0"}
                    hidden={inEMACondition === "0"}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Out parameters */}
          <div className="out-parameters-container">
            {/* Out parameters - Title */}
            <div>
              <h2>Out parameters</h2>
            </div>
            {/* Out parameters - and/or */}
            <div className='and_or_condiction'>
              {<Indication labelText="Condition:" hoverText="Choose whether the conditions within the 'Out' parameters should be met simultaneously ('And') or at least one condition should be met ('Or')." />}
              <select id="out_and_or" value={outAndOr} onChange={handleOutAndOrChange}>
                <option key="and" value="and">And</option>
                <option key="or" value="or">Or</option>
              </select>
            </div>
            {/* Out parameters - parameters */}
            <div>
              {/* Out parameters - RSI */}
              <div>
                {/* Out parameters - RSI condition */}
                <div>
                  {<Indication labelText="RSI Condition:" hoverText="Choose the condition type based on the Relative Strength Index (RSI) within the 'Out' parameters. Trigger between 0 and 100. If 'None', RSI is not used." />}
                  <select id="out_parameters_rsi_condition" value={outRSICondition} onChange={handleOutRSIConditionChange}>
                    <option key="0" value="0">None</option>
                    <option key="1" value="1">Superior or equal</option>
                    <option key="2" value="2">Inferior or equal</option>
                    <option key="3" value="3">Superior</option>
                    <option key="4" value="4">Inferior</option>
                    <option key="5" value="5">Equal</option>
                    <option key="6" value="6">Between</option>
                    <option key="7" value="7">Out of</option>
                  </select>
                  <p className="error-message">{outRSIConditionError}</p>
                </div>
                {/* Out parameters - RSI trigger1 */}
                <div>
                  {outRSICondition !== "0" ? (<label id="out_rsi_trigger1">RSI trigger {(outRSICondition !== "6" && outRSICondition !== "7") ? null : '1'}:</label>) : null}
                  <input
                    type="number"
                    id="out_rsi_trigger1"
                    value={outRSITrigger1}
                    min='0'
                    max='100'
                    step='0.5'
                    onChange={handleOutRSITrigger1Change}
                    disabled={outRSICondition === "0"}
                    hidden={outRSICondition === "0"}
                  />
                </div>
                {/* Out parameters - RSI trigger2 */}
                <div>
                  {(outRSICondition === "6" || outRSICondition === "7") ? (<label id="out_rsi_trigger2">RSI trigger 2:</label>) : null}
                  <input
                    type="number"
                    id="out_rsi_trigger2"
                    value={outRSITrigger2}
                    min='0'
                    max='100'
                    step='0.5'
                    onChange={handleOutRSITrigger2Change}
                    disabled={(outRSICondition !== "6" && outRSICondition !== "7")}
                    hidden={(outRSICondition !== "6" && outRSICondition !== "7")}
                  />
                </div>
                {/* Out parameters - RSI period */}
                <div>
                  {outRSICondition !== "0" ? (<label id="out_rsi_period" >RSI period:</label>) : null}
                  <input
                    type="number"
                    id="out_rsi_period"
                    value={outRSIPeriod}
                    min='1'
                    step='1'
                    onChange={handleOutRSIPeriodChange}
                    disabled={outRSICondition === "0"}
                    hidden={outRSICondition === "0"}
                  />
                </div>
              </div>
              {/* Out parameters - SMA */}
              <div>
                {/* Out parameters - SMA condition */}
                <div>
                  {<Indication labelText="SMA Condition:" hoverText="Choose the condition type based on the Simple Moving Average (SMA) within the 'Out' parameters. SMA is compared to the price. The SMA is compared to the price. If the condition is set to 'superior,' execute a trade when the SMA is higher than the price. If 'None', SMA is not used." />}
                  <select id="out_parameters_sma_condition" value={outSMACondition} onChange={handleOutSMAConditionChange}>
                    <option key="0" value="0">None</option>
                    <option key="1" value="1">Superior or equal</option>
                    <option key="2" value="2">Inferior or equal</option>
                    <option key="3" value="3">Superior</option>
                    <option key="4" value="4">Inferior</option>
                    <option key="5" value="5">Equal</option>
                  </select>
                  <p className="error-message">{outSMAConditionError}</p>
                </div>
                {/* Out parameters - SMA period */}
                <div>
                  {outSMACondition !== "0" ? (<label id="out_SMA_period" >SMA period:</label>) : null}
                  <input
                    type="number"
                    id="out_sma_period"
                    value={outSMAPeriod}
                    min='1'
                    step='1'
                    onChange={handleOutSMAPeriodChange}
                    disabled={outSMACondition === "0"}
                    hidden={outSMACondition === "0"}
                  />
                </div>
              </div>
              {/* Out parameters - EMA */}
              <div>
                {/* Out parameters - EMA condition */}
                <div>
                  {<Indication labelText="EMA Condition:" hoverText="Choose the condition type based on the Exponential Moving Average (EMA) within the 'Out' parameters. EMA is compared to the price. The EMA is compared to the price. If the condition is set to 'superior,' execute a trade when the EMA is higher than the price. If 'None', EMA is not used." />}
                  <select id="out_parameters_ema_condition" value={outEMACondition} onChange={handleOutEMAConditionChange}>
                    <option key="0" value="0">None</option>
                    <option key="1" value="1">Superior or equal</option>
                    <option key="2" value="2">Inferior or equal</option>
                    <option key="3" value="3">Superior</option>
                    <option key="4" value="4">Inferior</option>
                    <option key="5" value="5">Equal</option>
                  </select>
                  <p className="error-message">{outEMAConditionError}</p>
                </div>
                {/* Out parameters - EMA period */}
                <div>
                  {outEMACondition !== "0" ? (<label id="out_EMA_period" >EMA period:</label>) : null}
                  <input
                    type="number"
                    id="out_ema_period"
                    value={outEMAPeriod}
                    min='1'
                    step='1'
                    onChange={handleOutEMAPeriodChange}
                    disabled={outEMACondition === "0"}
                    hidden={outEMACondition === "0"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Simulation button */}
        <div className="simulation-button">
          <button onClick={handleSimulateClick} disabled={loading}>
        {loading ? 'Simulating...' : 'Simulate'}
        </button>
        </div>

      </div>
      <div>
        <SimulationDataChart data={simulationData.data} trade={simulationData.trade} />
      </div>
      <div>
        <Footer />
      </div>

    </>
  );
}

export default Simulation;
