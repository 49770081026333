import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './Indication.css';
import '../../css/App.css';


const Indication = ({ labelText, hoverText }) => {

    const [isHovered, setIsHovered] = useState(false);

    

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div>
      <label>{labelText + " "} 
      <FontAwesomeIcon
        icon={faInfoCircle}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      />
      </label>
      {isHovered && (
        <div className='indication-box'>
          {hoverText}
        </div>
      )}
    </div>
  );
};

export default Indication;