import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="nav-container">
      <div className="container">
        <Link to="/" className="nav-link">
          Simulation
        </Link>
        <Link to="/about" className="nav-link">
          About
        </Link>
      </div>
      
    </nav>
  );
};

export default Navbar;
